<template>
  <div>
    <DataTable
      v-model:filters="filters"
      :value="items"
      :loading="loading"
      :rows="10"
      lazy
      row-hover
      paginator
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      scrollable
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column
        field="kt_no"
        header="Kode Transfer"
        style="min-width: 250px"
        sortable
      >
        <template #body="{ data }">
          {{ data.kt_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="kas_between"
        field="kt_at"
        header="Tanggal"
        style="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.kt_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="kas_id_from"
        header="Dari"
        style="min-width: 250px"
        sortable
      >
        <template #body="{ data }">
          {{ data.kas_from.nama }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="accounts"
            optionValue="id"
            optionLabel="nama"
            class="p-column-filter text-right"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column field="kas_id_to" header="Ke" style="min-width: 250px" sortable>
        <template #body="{ data }">
          {{ data.kas_to.nama }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="accounts"
            optionValue="id"
            optionLabel="nama"
            class="p-column-filter text-right"
            @change="filterCallback()"
          />
        </template>
      </Column>

      <Column
        field="nilai"
        header="Nilai"
        sortable
      >
        <template #body="{ data }">
          {{ formatCurrency(data.nilai) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            :useGrouping="false"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
            @keypress="numberOnly"
          />
        </template>
      </Column>

      <Column field="keterangan" header="Keterangan" style="min-width: 250px">
        <template #body="{ data }">
          {{ data.keterangan }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
          </template>
      </Column>

      <Column style="flex-grow: 1; justify-content: flex-end">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import CalendarFilter from '@/components/CalendarFilter'
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    accounts: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: { CalendarFilter },
  data() {
    return {
      options: this.gridOptions,
      filters: {
        kt_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kas_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kas_id_from: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kas_id_to: { value: null, matchMode: FilterMatchMode.CONTAINS },
        keterangan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nilai: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    numberOnly(e) {
      if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
        e.preventDefault()
      } else {
        return true
      }
    }
  },
}
</script>
